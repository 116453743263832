.transportation-amenities-toggle-block {
  &__container {
    border: none;
    background: none;
    width: 100%;
    padding: 0;
  }

  &__condensed-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: 70px;
    border: none;
    background: none;
    padding: 0;
    border-top: solid 1px #d8d8d8;
    padding-right: 15px;
    width: 100%;
  }

  &:first-of-type {
    .transportation-amenities-toggle-block__condensed-item {
      border-top: none;
    }
  }

  &__chevron-icon {
    span {
      font-size: 8px;
      display: flex;
    }
  }

  &__optional-text {
    border-left: solid 1px black;
    margin-left: 6px;
    padding-left: 6px;
  }
}